import React, { useContext } from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DashboardIcon from "@material-ui/icons/Dashboard";
import Gavel from "@material-ui/icons/Gavel";
import { Link } from "react-router-dom";
import { UserRoles } from "../../../../../../constants/config";
import UserContext from "../../../../../../context/UserContext";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import GroupIcon from "@material-ui/icons/Group";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import NotesIcon from "@material-ui/icons/Notes";
import { Routes } from "../../../../../../constants/config";

const SideNav = () => {
    const currentUser = useContext(UserContext);
    const currentUserRole = currentUser["custom:UserRole"];
    return (
        <div>
            <ListItem button component={Link} to={Routes.Dashboard.Path}>
                <ListItemIcon>
                    <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
            </ListItem>
            {currentUserRole === UserRoles.SuperAdmin ? (
                <ListItem button component={Link} to={Routes.LawFirms.Path}>
                    <ListItemIcon>
                        <Gavel />
                    </ListItemIcon>
                    <ListItemText primary="Law Firms" />
                </ListItem>
            ) : null}
            {currentUserRole === UserRoles.Admin ? (
                <>
                    <ListItem
                        button
                        component={Link}
                        to={`${Routes.LawFirms.Path}/${currentUser["custom:FirmId"]}/ManageUsers`}
                    >
                        <ListItemIcon>
                            <GroupIcon />
                        </ListItemIcon>
                        <ListItemText primary="Users" />
                    </ListItem>
                    <ListItem
                        button
                        component={Link}
                        to={`/LawFirms/${currentUser["custom:FirmId"]}/ManageTemplates`}
                    >
                        <ListItemIcon>
                            <FileCopyIcon />
                        </ListItemIcon>
                        <ListItemText primary="Templates" />
                    </ListItem>
                </>
            ) : null}
            {currentUserRole !== UserRoles.SuperAdmin ? (
                <ListItem button component={Link} to={Routes.Leads.Path}>
                    <ListItemIcon>
                        <ContactMailIcon />
                    </ListItemIcon>
                    <ListItemText primary="Leads" />
                </ListItem>
            ) : null}
            <ListItem button component={Link} to={Routes.FieldReferences.Path}>
                <ListItemIcon>
                    <NotesIcon />
                </ListItemIcon>
                <ListItemText primary="Field References" />
            </ListItem>
        </div>
    );
};

export default SideNav;
