const AvailableCounties = [
    {
        CountyName: "Leon",
        CaseTypes: [
            { CourtType: "Family Law", title: "Alimony" },
            { CourtType: "Family Law", title: "Annulment" },
            { CourtType: "Family Law", title: "Child Custody" },
            { CourtType: "Family Law", title: "Dating Violence" },
            { CourtType: "Family Law", title: "Disestablishment of Paternity" },
            { CourtType: "Family Law", title: "Dissolution of Marriage" },
            { CourtType: "Family Law", title: "Domestic Foreign Divorce Decree" },
            { CourtType: "Family Law", title: "Domestic Violence Injunction" },
            { CourtType: "Family Law", title: "Enforcement" },
            { CourtType: "Family Law", title: "Enforcement IV-D" },
            { CourtType: "Family Law", title: "Injunction" },
            { CourtType: "Family Law", title: "Injunction Relief" },
            { CourtType: "Family Law", title: "Paternity" },
            { CourtType: "Family Law", title: "Paternity 4D-PA" },
            { CourtType: "Family Law", title: "Petition for Modification F.S.61.14" },
            { CourtType: "Family Law", title: "Repeat Violence Injunction" },
            { CourtType: "Family Law", title: "Sexual Violence Injunction" },
            { CourtType: "Family Law", title: "Stalking Injunction" },
            { CourtType: "Family Law", title: "Support" },
            { CourtType: "Family Law", title: "Support 4D - New Case Number" },
            { CourtType: "Family Law", title: "Support 4D-PA" },
            { CourtType: "Criminal", title: "Felony" },
            { CourtType: "Criminal", title: "Misdemeanor" }
        ]
    },
    {
        CountyName: "Osceola",
        CaseTypes: [
            { CourtType: "Family Law", title: "Administrative Support"},
            { CourtType: "Family Law", title: "Administrative Uifsa"},
            { CourtType: "Family Law", title: "Dissolution"},
            { CourtType: "Family Law", title: "Dissolution w/Children"},
            { CourtType: "Family Law", title: "IV-D Child Support"},
            { CourtType: "Family Law", title: "IV-D Uifsa"},
            { CourtType: "Family Law", title: "Other Domestic"},
            { CourtType: "Family Law", title: "Paternity"},
            { CourtType: "Family Law", title: "Simplified Dissolution"}
        ]
    },
    {
        CountyName: "Orange",
        CaseTypes: [
            { CourtType: "Family Law", title: "Dating Violence"},
            { CourtType: "Family Law", title: "Disestablishment of Paternity"},
            { CourtType: "Family Law", title: "Dissolution of Marriage"},
            { CourtType: "Family Law", title: "Dissolution of Marriage with Children"},
            { CourtType: "Family Law", title: "Domestic Violence"},
            { CourtType: "Family Law", title: "Domestic Violence with Children"},
            { CourtType: "Family Law", title: "Income Deduction Order"},
            { CourtType: "Family Law", title: "Parental Responsibility and Time-sharing"},
            { CourtType: "Family Law", title: "Paternity"},
            { CourtType: "Family Law", title: "Proceeding to Est Administrative Support Order"},
            { CourtType: "Family Law", title: "Repeat Violence"},
            { CourtType: "Family Law", title: "Simplified Dissolution of Marriage"},
            { CourtType: "Family Law", title: "Stalking"},
            { CourtType: "Family Law", title: "Temporary Custody by Extended Family Member"},
            { CourtType: "Family Law", title: "Uifsa Enforcement"},
            { CourtType: "Criminal", title: "County Ordinance"},
            { CourtType: "Criminal", title: "Criminal Felony"},
            { CourtType: "Criminal", title: "Infraction"},
            { CourtType: "Criminal", title: "Misdemeanor"},
            { CourtType: "Criminal", title: "Municipal Ordinance"},
            { CourtType: "Traffic", title: "Criminal Traffic"}
        ]
    }
];

export default AvailableCounties;