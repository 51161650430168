import React from "react";
import { Grid } from '@material-ui/core';

const FieldReferences = () => {

    return (
        <>
            <h2>Criminial/Traffic Records</h2>
            <p>The fields below are available for use to generate letters for <strong>Criminal</strong> and <strong>Traffic</strong> Cases</p>
            <Grid container spacing={4}>
                <Grid item xs={12} sm={6} md={4}>{"{{FirstName}}"}</Grid>
                <Grid item xs={12} sm={6} md={4}>{"{{MiddleName}}"}</Grid>
                <Grid item xs={12} sm={6} md={4}>{"{{LastName}}"}</Grid>
            </Grid>
            <Grid container spacing={4}>
                <Grid item xs={12} sm={6} md={4}>{"{{Address1}}"}</Grid>
                <Grid item xs={12} sm={6} md={4}>{"{{Address2}}"}</Grid>
                <Grid item xs={12} sm={6} md={4}>{"{{City}}"}</Grid>
            </Grid>
            <Grid container spacing={4}>
                <Grid item xs={12} sm={6} md={4}>{"{{State}}"}</Grid>
                <Grid item xs={12} sm={6} md={4}>{"{{Zip}}"}</Grid>
                <Grid item xs={12} sm={6} md={4}>{"{{County}}"}</Grid>
            </Grid>
            <Grid container spacing={4}>
                <Grid item xs={12} sm={6} md={4}>{"{{ArrestDate}}"}</Grid>
                <Grid item xs={12} sm={6} md={4}>{"{{DateFiled}}"}</Grid>
                <Grid item xs={12} sm={6} md={4}>{"{{OffenseDate}}"}</Grid>
            </Grid>
            <Grid container spacing={4}>
                <Grid item xs={12} sm={6} md={4}>{"{{ArrestAgency}}"}</Grid>
                <Grid item xs={12} sm={6} md={4}>{"{{CaseNumber}}"}</Grid>
                <Grid item xs={12} sm={6} md={4}>{"{{Charge}}"}</Grid>
            </Grid>    
            <Grid container spacing={4}>
                <Grid item xs={12} sm={6} md={4}>{"{{DLNumber}}"}</Grid>
                <Grid item xs={12} sm={6} md={4}>{"{{DLState}}"}</Grid>
            </Grid>
            <Grid container spacing={4}>
                <Grid item xs={12} sm={6} md={4}>{"{{HasAttorney}} (Yes/No)"}</Grid>
                <Grid item xs={12} sm={6} md={4}>{"{{AttorneyName}}"}</Grid>
            </Grid>

            <h2 style={{ marginTop: "50px" }}>Family Law Records</h2>
            <p>The fields below are available for use to generate letters for <strong>Family Law</strong> Cases</p>
            <Grid container spacing={4}>
                <Grid item xs={12} sm={6} md={4}>{"{{FirstName}}"}</Grid>
                <Grid item xs={12} sm={6} md={4}>{"{{MiddleName}}"}</Grid>
                <Grid item xs={12} sm={6} md={4}>{"{{LastName}}"}</Grid>
            </Grid>
            <Grid container spacing={4}>
                <Grid item xs={12} sm={6} md={4}>{"{{Address1}}"}</Grid>
                <Grid item xs={12} sm={6} md={4}>{"{{Address2}}"}</Grid>
                <Grid item xs={12} sm={6} md={4}>{"{{City}}"}</Grid>
            </Grid>
            <Grid container spacing={4}>
                <Grid item xs={12} sm={6} md={4}>{"{{State}}"}</Grid>
                <Grid item xs={12} sm={6} md={4}>{"{{Zip}}"}</Grid>
                <Grid item xs={12} sm={6} md={4}>{"{{County}}"}</Grid>
            </Grid>
            <Grid container spacing={4}>
                <Grid item xs={12} sm={6} md={4}>{"{{CaseNumber}}"}</Grid>
                <Grid item xs={12} sm={6} md={4}>{"{{CaseType}}"}</Grid>
                <Grid item xs={12} sm={6} md={4}>{"{{PartyDesc}} (Petitioner/Respondent)"}</Grid>
            </Grid>
            <Grid container spacing={4}>
                <Grid item xs={12} sm={6} md={4}>{"{{OpposingPartyFirstName}}"}</Grid>
                <Grid item xs={12} sm={6} md={4}>{"{{OpposingPartyMiddleName}}"}</Grid>
                <Grid item xs={12} sm={6} md={4}>{"{{OpposingPartyLastName}}"}</Grid>
            </Grid>    
            <Grid container spacing={4}>
                <Grid item xs={12} sm={6} md={4}>{"{{OpposingPartyHasAttorney}} (Yes / No)"}</Grid>
                <Grid item xs={12} sm={6} md={4}>{"{{DLState}}"}</Grid>
            </Grid>
        </>
    );
}

export default FieldReferences;