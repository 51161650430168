import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import FormDropDown from "../../../../components/FormDropDown";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import CalendarTodayOutlined from "@material-ui/icons/CalendarTodayOutlined";
import GavelRounded from "@material-ui/icons/GavelRounded";
import LocationCityRounded from "@material-ui/icons/LocationCityRounded";
import SettingsRounded from "@material-ui/icons/SettingsRounded";
import DatePicker from "../../../../components/DatePicker";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: "5px 4px",
        display: "flex",
        alignItems: "center",
        width: "100%",
        backgroundColor: theme.palette.secondary.light,
        color: "#fff",
        marginBottom: -3,
        flexDirection: "row",
        flexWrap: "wrap",
        [theme.breakpoints.up("sm")]: {
            flexWrap: "wrap",
        },
        [theme.breakpoints.down("xs")]: {
            flexDirection: "row",
        },
        justifyContent: "space-around",
    },
    input: {
        color: "inherit",
        "& > input": {
            background: "#fff",
            color: "#525865",
            borderradius: "4px",
            border: "1px solid #d1d1d1",
            boxShadow: "inset 1px 2px 8px rgba(0, 0, 0, 0.07)",
            fontFamily: "inherit",
            lineHeight: "1.45",
            outline: "none",
            padding: "0.6em 1.45em  0.7em",
            [theme.breakpoints.down("xs")]: {
                padding: "0.6em",
            },
            "-webkit-transition": ".18s ease-out",
            "-moz-transition": ".18s ease-out",
            "-o-transition": ".18s ease-out",
            transition: ".18s ease-out",
        },
        "& >input:hover": {
            boxShadow: " inset 1px 2px 8px rgba(0, 0, 0, 0.02)",
        },
        "& >input:focus": {
            color: "#4b515d",
            border: "1px solid #B8B6B6",
            boxShadow:
                "inset 1px 2px 4px rgba(0, 0, 0, 0.01), 0px 0px 8px rgba(0, 0, 0, 0.2)",
        },
    },
    iconButton: {
        padding: 10,
        color: "inherit",
    },
    divider: {
        height: 28,
        margin: 5,
        color: "inherit",
        [theme.breakpoints.down("sm")]: {
            height: 2,
            margin: 1,
        },
    },
    plane: {
        textTransform: "capitalize",
        color: "inherit",
        borderColor: "inherit",
        marginBottom: 5,
    },
    blue: {
        backgroundColor: theme.palette.grey[300],
        padding: theme.spacing(2),
        marginLeft: "-8px",
        marginRight: "-8px",
        marginBottom: "-8px",
        alignItems: "flex-end",
        "& > button": {
            width: "50px",
        },
    },
    searchbtn: {
        marginLeft: 10,
        [theme.breakpoints.down("xs")]: {
            marginLeft: 2,
        },
    },
    savebtn: {
        display: "",
        [theme.breakpoints.down("xs")]: {
            display: "none",
        },
    },
    savelnk: {
        display: "none",
        [theme.breakpoints.down("xs")]: {
            display: "block",
        },
    },
}));

export default function SearchBar(props) {
    const classes = useStyles();

    return (
        <Paper component="form" className={classes.root}>
            <FormDropDown title="County/Court Type" startIcon={<LocationCityRounded />}>
                <h3>County</h3>
                <FormControl
                    component="fieldset"
                    className={classes.formControl}
                >
                    <FormGroup>
                        <RadioGroup
                            aria-label="Counties"
                            name="counties"
                            value={props.selectedCounty}
                            onChange={(e) => props.handleCounties(e.target.value)}
                        >
                            {props.counties.map((county) => (
                                <FormControlLabel
                                    key={county.title}
                                    value={county.title}
                                    control={<Radio />}
                                    label={county.title}
                                />
                            ))}
                        </RadioGroup>                        
                    </FormGroup>
                </FormControl>
                {props.courtTypes.length > 0 &&
                    <> 
                        <Divider />
                        <h3>Court Type</h3>
                        <FormGroup>
                            <RadioGroup
                                aria-label="CourtType"
                                name="courtType"
                                value={props.selectedCourtType}
                                onChange={(e) => props.handleCourtTypes(e.target.value)}
                            >
                                {props.courtTypes.map((courtType) => (
                                    <FormControlLabel
                                        key={courtType}
                                        value={courtType}
                                        control={<Radio />}
                                        label={courtType}
                                    />
                                ))}        
                            </RadioGroup>
                        </FormGroup>
                    </>
                }
            </FormDropDown>

            <Divider className={classes.divider} orientation="vertical" />

            <FormDropDown title="Dates" startIcon={<CalendarTodayOutlined />}>
                <DatePicker
                    label="From"
                    selectedDate={props.fromDate}
                    setSelectedDate={props.setFromDate}
                />
                <DatePicker
                    label="To"
                    selectedDate={props.toDate}
                    setSelectedDate={props.setToDate}
                />
            </FormDropDown>
            
            <Divider className={classes.divider} orientation="vertical" />
            
            <FormDropDown title="Case Type" startIcon={<GavelRounded />}>
                <FormControl
                    component="fieldset"
                    className={classes.formControl}
                >
                    <FormGroup>
                        {props.caseTypes.map((caseType) => (
                            <FormControlLabel
                                key={caseType.title}
                                control={
                                    <Checkbox
                                        checked={caseType.checked}
                                        onChange={props.handleCaseTypes}
                                        name={caseType.title}
                                    />
                                }
                                label={caseType.title}
                            />
                        ))}
                    </FormGroup>
                </FormControl>
            </FormDropDown>
            
            <Divider className={classes.divider} orientation="vertical" />

            <FormDropDown title="Lead Options" startIcon={<SettingsRounded />}>
                <h3>Lead Role</h3>
                <FormControl component="fieldset">
                    <RadioGroup
                        aria-label="Lead Role"
                        name="leadRole"
                        onChange={(e) => props.setLeadRole(e.target.value)}
                        value={props.leadRole}
                    >
                        { props.selectedCourtType === "Family Law" &&
                            <>
                                <FormControlLabel
                                    value="Petitioner"
                                    control={<Radio />}
                                    label="Petitioner"
                                />

                                <FormControlLabel
                                    value="Respondent"
                                    control={<Radio />}
                                    label="Respondent"
                                />
                            </>
                        }
                        { (props.selectedCourtType === "Criminal" || props.selectedCourtType === "Traffic") &&
                            <FormControlLabel
                                value="Defendant"
                                control={<Radio />}
                                label="Defendant"
                            />
                        }
                    </RadioGroup>
                </FormControl>
                <Divider />
                <h3>Leads to Include</h3>
                <FormControl component="fieldset">
                    <RadioGroup
                        name="leadToInclude"
                        onChange={(e) =>
                            props.setLeadToInclude(parseInt(e.target.value))
                        }
                        value={props.leadToInclude}
                    >
                        <FormControlLabel
                            value={1}
                            control={<Radio />}
                            label="All leads"
                        />
                        <FormControlLabel
                            value={2}
                            control={<Radio />}
                            label="Leads not yet mailed"
                            selected={true}
                        />
                        <FormControlLabel
                            value={3}
                            control={<Radio />}
                            label="Leads already mailed"
                        />
                    </RadioGroup>
                </FormControl>
            </FormDropDown>
        </Paper>
    );
}
