import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import {
    SignIn,
    ResetPassword,
    Dashboard as DashboardView,
    LawFirms,
    Users,
    Leads,
    MailTemplates,
    VerifyAccount,
    FieldReferences
} from "./views";
import ProtectedRoute from "./components/ProtectedRoute";
import UserContext from "./context/UserContext";
import auth from "./services/authService";
import Dashboard from "./layouts/Dashboard/Dashboard";

const Routes = () => {
    const currentUser = auth.getCurrentUser();
    return (
        <UserContext.Provider value={currentUser}>
            <Switch>
                <Route path="/not-found">
                    <div>Not Found</div>
                </Route>
                <Route path="/SignIn">
                    <SignIn />
                </Route>
                <Route path="/VerifyAccount">
                    <VerifyAccount />
                </Route>
                <Route path="/ResetPassword">
                    <ResetPassword />
                </Route>
                <ProtectedRoute path="/Dashboard">
                    <Dashboard>
                        <DashboardView />
                    </Dashboard>
                </ProtectedRoute>
                <ProtectedRoute path="/LawFirms/:LawFirmId/ManageUsers">
                    <Dashboard>
                        <Users />
                    </Dashboard>
                </ProtectedRoute>
                <ProtectedRoute path="/LawFirms/:LawFirmId/ManageTemplates">
                    <Dashboard>
                        <MailTemplates />
                    </Dashboard>
                </ProtectedRoute>
                <ProtectedRoute path="/LawFirms">
                    <Dashboard>
                        <LawFirms />
                    </Dashboard>
                </ProtectedRoute>
                <ProtectedRoute path="/Leads">
                    <Dashboard>
                        <Leads />
                    </Dashboard>
                </ProtectedRoute>
                <ProtectedRoute path="/FieldReferences">
                    <Dashboard>
                        <FieldReferences />
                    </Dashboard>
                </ProtectedRoute>
                <Redirect exact from="/" to="/Dashboard" />
                <Redirect to="/not-found" />
            </Switch>
        </UserContext.Provider>
    );
};

export default Routes;